@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/* ./src/index.css */
@tailwind base;

html,body {
    font-family: 'Roboto','Noto Sans JP',sans-serif;
}

.fade-enter{
    transform: translateX(-10%);
    /* opacity: 1; */
 }
 .fade-enter-active{
    transform: translateX(0);
    /* opacity: 0; */
}
.fade-exit{
    transform: translateX(0);
    /* opacity: 0; */
}
.fade-exit-active{
    transform: translateX(-10%);
    /* opacity: 0; */
}
.fade-enter-active,
.fade-exit-active{
    transition: all 750ms ease;
}



.first-element-enter {
    position: absolute;
    transform: translateX(-110%);
}
.first-element-enter-active {
    transform: translateX(0%);
}
.first-element-exit {
    position: absolute;
}
.first-element-exit-active {
    transform: translateX(-110%);
}
.first-element-enter-active,
.first-element-exit-active {
    transition: all 350ms ease-out;
}






.second-element-enter {
    transform: translateX(110%);
}
.second-element-enter-active {
    transform: translateX(0%);
}
.second-element-exit {
}
.second-element-exit-active {
    transform: translateX(110%);
}
.second-element-enter-active,
.second-element-exit-active {
    transition: all 350ms ease-out;
}



@tailwind components;
@tailwind utilities;

/* @layer base {
    button {
        @apply bg-indigo-500;
        @apply hover:indigo-700;
        @apply px-4;
        @apply py-2;
        @apply rounded-sm;
    }
} */